"use client";
import styled from "@emotion/styled";
import {
  Button,
  Input,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import Link from "next/link";

export const Avtar = ({ space, color }) => {
  return (
    <svg
      width="111"
      height="61"
      viewBox="0 0 111 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={space ? space : "mb-6"}
    >
      <path
        d="M23.7324 9.14796C24.254 10.4967 24.7693 11.8473 25.249 13.2134C25.5118 13.9342 25.7673 14.6617 26.0786 15.3626C27.7436 19.1024 29.4553 22.825 31.0279 26.6048C31.9849 28.9362 32.9173 31.2775 33.8251 33.6285L35.1756 37.1589L36.4857 40.7038C36.7318 41.4279 36.9183 42.1702 37.0434 42.9235C37.1087 43.2297 37.0987 43.5468 37.0143 43.8485C36.9299 44.1502 36.7734 44.4279 36.5578 44.6586C35.9749 45.3108 35.0487 45.1909 34.5057 44.306C34.1668 43.722 33.9039 43.0987 33.723 42.4508C33.282 40.9826 32.7789 39.5387 32.2137 38.119C31.6538 36.6972 31.0345 35.2981 30.3559 33.9217C29.9821 33.1616 29.546 32.3887 29.3508 31.5867C28.7241 29.0252 27.552 26.6498 26.7182 24.1598C26.0971 22.3095 25.3326 20.503 24.6541 18.6695L22.8346 13.7664C22.2187 12.1357 21.6437 10.4882 21.0325 8.85461C20.8128 8.21169 20.6366 7.5553 20.5049 6.88971C20.3964 6.25723 20.3237 5.61935 20.2873 4.97901C20.2537 4.41269 20.6181 4.30087 21.0367 4.80322C21.6355 5.52522 22.1821 6.28729 22.6726 7.08386C22.8744 7.41074 23.0578 7.7506 23.2266 8.09785C23.3805 8.45114 23.535 8.8057 23.6886 9.15816L23.7324 9.14796Z"
        fill={color ? color : "#ABE69E"}
      />
      <path
        d="M66.1359 4.7983L65.9294 6.63238L65.6772 8.46385C65.5896 9.11172 65.5013 9.76285 65.4703 10.415C65.4268 11.2848 65.3943 12.1553 65.3654 13.0258L65.2631 15.6354L65.0701 20.8531L64.8163 27.2768L64.5984 33.7025C64.5581 34.3528 64.4702 34.9994 64.3353 35.6374C64.291 35.9011 64.1844 36.151 64.024 36.3672C63.8635 36.5834 63.6537 36.7599 63.4113 36.8826C62.7498 37.2352 62.0543 36.868 61.8877 35.992C61.792 35.4204 61.7718 34.839 61.8277 34.2624C62.0283 31.639 62.0595 29.0059 61.9211 26.3786C61.8376 25.6523 61.8389 24.9189 61.9249 24.1928C62.3229 21.9559 62.2457 19.6838 62.5058 17.4387C62.7003 15.7714 62.7734 14.0879 62.96 12.4174C63.3044 9.43971 63.6783 6.46758 64.1629 3.50933C64.3346 2.38432 64.695 1.29486 65.2296 0.28503C65.4601 -0.152531 65.8401 -0.0811858 65.938 0.4638C66.0721 1.24541 66.1538 2.03477 66.1826 2.82684C66.1938 3.4819 66.1653 4.13704 66.0974 4.78881L66.1359 4.7983Z"
        fill={color ? color : "#ABE69E"}
      />
      <path
        d="M101.532 23.3075C100.837 24.1276 100.137 24.9451 99.4337 25.7599C99.0413 26.1767 98.6746 26.616 98.3354 27.0755C97.4594 28.3177 96.6111 29.5794 95.7636 30.8403C94.9145 32.1001 94.0579 33.3534 93.1814 34.5922C92.1053 36.1201 91.0134 37.6363 89.9249 39.1551L86.6338 43.6934C86.2785 44.1449 85.8683 44.5521 85.4123 44.9062C84.9868 45.2623 84.3806 45.2702 83.7236 45.0869C82.7664 44.8199 82.1168 44.0038 82.3598 43.2475C82.5302 42.7701 82.8 42.3325 83.1521 41.9625C84.6826 40.237 86.0003 38.3409 87.0781 36.3135C87.3792 35.7523 87.6507 35.1377 88.1259 34.7197C89.6355 33.3809 90.6166 31.6516 91.9471 30.1838C92.9311 29.0908 93.8077 27.9101 94.7703 26.7989C95.6285 25.8081 96.4943 24.824 97.3677 23.8467L100.051 20.9658C100.746 20.2105 101.616 19.6289 102.588 19.2694C102.683 19.2273 102.79 19.2142 102.893 19.2318C102.997 19.2494 103.093 19.2969 103.169 19.3682C103.244 19.4395 103.297 19.5313 103.319 19.632C103.341 19.7326 103.332 19.8373 103.293 19.9329C103.06 20.5936 102.788 21.2402 102.477 21.8693C102.186 22.3605 101.858 22.83 101.496 23.2738L101.532 23.3075Z"
        fill={color ? color : "#ABE69E"}
      />
      <path
        d="M109.402 54.2594C109.137 54.3808 108.877 54.5116 108.605 54.6174L107.786 54.9322C107.493 55.0362 107.21 55.1641 106.94 55.3145C106.233 55.741 105.54 56.1952 104.827 56.6086C104.118 57.0305 103.407 57.4482 102.682 57.8393C101.79 58.3221 100.89 58.7895 99.9753 59.2271L97.2304 60.5406C96.9331 60.6581 96.6159 60.7202 96.2953 60.7237C95.982 60.7453 95.631 60.4668 95.2878 60.0461C94.7878 59.433 94.5694 58.624 94.8507 58.271C95.031 58.0612 95.2746 57.9128 95.5466 57.8474C96.7465 57.497 97.8635 56.9169 98.8331 56.1409C99.1059 55.9263 99.3659 55.6556 99.7152 55.6124C100.825 55.4635 101.69 54.815 102.709 54.4819C103.469 54.2394 104.159 53.8492 104.91 53.5854C106.246 53.1092 107.566 52.6035 108.923 52.1691C109.482 51.9809 110.086 51.9675 110.653 52.1308C110.722 52.1521 110.786 52.1879 110.839 52.2357C110.893 52.2836 110.935 52.3423 110.963 52.4078C110.991 52.4733 111.004 52.544 111.001 52.6149C110.998 52.6858 110.979 52.7553 110.946 52.8184C110.829 52.9806 110.705 53.1394 110.577 53.2926C110.454 53.4456 110.318 53.588 110.17 53.7183C109.925 53.9097 109.663 54.0787 109.386 54.2232L109.402 54.2594Z"
        fill={color ? color : "#ABE69E"}
      />
      <path
        d="M2.9373 47.8071C3.54333 48.1486 4.14465 48.4957 4.74124 48.8483C5.05243 49.0427 5.3753 49.2186 5.70809 49.375C6.61107 49.7531 7.51145 50.1418 8.40532 50.5477C9.29729 50.9571 10.1726 51.3996 11.0312 51.8751C12.0809 52.471 13.1131 53.0981 14.1167 53.7673C15.1205 54.436 16.1051 55.1312 17.0706 55.8529C17.359 56.0899 17.6045 56.3729 17.7968 56.6899C17.9989 56.9868 17.9307 57.4252 17.7174 57.9043C17.406 58.6016 16.7467 59.0983 16.2593 58.9629C15.9587 58.8656 15.689 58.6938 15.4762 58.4642C14.4753 57.4669 13.282 56.6745 11.9656 56.1332C11.6021 55.9786 11.1966 55.8636 10.9342 55.5783C10.0958 54.66 8.93654 54.2186 7.94949 53.5177C7.58045 53.2591 7.19738 53.0202 6.80195 52.8022C6.41141 52.5758 6.01378 52.3613 5.62547 52.1301C4.23986 51.3076 2.84031 50.4933 1.40136 49.7421C0.806407 49.4073 0.336896 48.8943 0.0619516 48.2785C-0.0931938 47.9944 0.0480962 47.6885 0.429299 47.5429L0.817938 47.4389C0.877521 47.4198 0.940357 47.4123 1.00288 47.4169L1.17868 47.4202C1.41057 47.4264 1.64187 47.4463 1.87132 47.4798C2.05535 47.5084 2.23575 47.5561 2.40949 47.6221C2.57903 47.6953 2.74581 47.7758 2.91615 47.846L2.9373 47.8071Z"
        fill={color ? color : "#ABE69E"}
      />
    </svg>
  );
};
export const CheckMark = () => {
  return (
    <svg
      width="78"
      height="75"
      viewBox="0 0 78 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.9996 14.4368C65.1784 14.277 65.321 14.0809 65.4179 13.8616C65.5148 13.6423 65.5637 13.4048 65.5613 13.165C65.559 12.9252 65.5054 12.6887 65.4042 12.4714C65.303 12.254 65.1565 12.0608 64.9746 11.9046C64.7829 11.7402 64.5927 11.5767 64.404 11.414C62.9638 10.1759 61.4752 8.89377 59.9177 7.80027C48.0199 -0.573428 35.6579 -0.620528 23.1767 3.82037C11.8761 7.83827 4.619 15.8767 1.6041 27.7078C-1.7592 40.926 1.1859 52.587 10.3637 62.372C10.6025 62.625 10.8462 62.877 11.0895 63.119C16.0197 68.049 22.1604 71.093 27.026 73.211C29.1143 74.123 39.8298 75.231 42.7291 74.799C59.9589 72.226 71.2975 62.508 75.2466 46.25C76.0006 43.065 76.3761 39.8028 76.3652 36.5302C76.3481 33.0932 75.7701 29.6821 74.654 26.4313C74.5727 26.1882 74.4387 25.966 74.2615 25.7806C74.0843 25.5953 73.8684 25.4515 73.6291 25.3593C73.3899 25.2672 73.1332 25.2292 72.8775 25.2478C72.6218 25.2665 72.3734 25.3415 72.15 25.4674L72.1032 25.494C71.7483 25.6973 71.4762 26.0188 71.3344 26.4024C71.1926 26.786 71.1903 27.2073 71.3278 27.5925C72.3352 30.3866 72.8489 33.3348 72.8459 36.305C72.811 39.8684 72.3286 43.413 71.41 46.856C68.6877 56.751 62.1795 63.962 51.5131 68.895C40.6696 74.086 25.1192 68.838 20.9817 66.106C16.4086 62.98 12.4071 59.09 9.1522 54.608C4.0476 47.38 2.9023 36.9918 5.6454 27.4947C6.929 23.1097 9.1724 19.0654 12.2129 15.6549C15.2533 12.2445 19.0147 9.55337 23.2241 7.77687C34.1751 3.22577 45.1632 2.42297 55.8803 9.23887C57.3173 10.1537 60.981 13.0306 62.8329 14.4998C63.1436 14.7462 63.5314 14.8749 63.9278 14.863C64.3242 14.8511 64.7036 14.6994 64.9989 14.4348L64.9996 14.4368Z"
        fill="#0096AC"
      />
      <path
        d="M34.0569 48.284C34.7908 47.586 35.194 47.233 35.563 46.847C47.3958 34.4851 60.3546 23.3554 73.3901 12.3118C73.8827 11.8947 74.3869 11.3625 74.9701 11.2131C75.6731 11.0334 76.8196 11.2401 77.1338 11.6218C77.3403 11.9039 77.4707 12.2343 77.5127 12.5814C77.5547 12.9284 77.5068 13.2804 77.3736 13.6036C77.0672 14.4071 76.3209 15.0952 75.6439 15.6966C71.6032 19.2869 67.4499 22.7535 63.4807 26.4179C55.1835 34.0807 46.9391 41.8 38.7476 49.577C35.1582 52.977 33.7768 53.115 30.474 49.482C28.2998 47.089 21.7384 39.0248 19.7289 36.4839C19.445 36.0889 19.1922 35.6726 18.9726 35.2386C18.5194 34.4348 18.2231 33.5492 19.124 32.926C20.1276 32.2321 20.8397 32.9562 21.4346 33.7103C22.0366 34.4738 22.5499 35.3123 23.1945 36.0359C25.1955 38.2812 31.8947 45.915 34.0569 48.284Z"
        fill="#0096AC"
      />
    </svg>
  );
};
export const BgImg2Wrap = ({ overviewClass }) => {
  return (
    <svg
      viewBox="0 0 451 499"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-[350px] sm:w-[451px] h-[499px] ${overviewClass ? overviewClass : "bgImg2Wrap"}`}
    >
      <rect
        width="578.108"
        height="186.46"
        transform="matrix(-0.937374 0.348326 0.306184 0.951972 393.902 119.668)"
        fill="#FFDA73"
      />
      <rect
        width="388.187"
        height="125.77"
        transform="matrix(-0.937374 0.348326 0.306184 0.951972 242.039 0.54248)"
        fill="#214F9A"
      />
    </svg>
  );
};
export const BgImgWrap = ({ classNames }) => {
  return (
    <svg
      width="427"
      height="455"
      viewBox="0 0 427 455"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`bgImgWrap w-[360px] h-[360px] sm:w-[427px] sm:h-[455px] ${
        classNames ? classNames : ""
      }`}
    >
      <rect
        width="572.284"
        height="171.933"
        transform="matrix(0.946914 0.321488 -0.332053 0.943261 57.0898 108.84)"
        fill="#ABE69E"
      />
      <rect
        width="384.276"
        height="115.971"
        transform="matrix(0.946914 0.321488 -0.332053 0.943261 208.953 0)"
        fill="#214F9A"
      />
    </svg>
  );
};
export const BgImg3Wrap = () => {
  return (
    <svg
      width="484"
      height="455"
      viewBox="0 0 484 455"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="bgImg3Wrap"
    >
      <rect
        width="572.284"
        height="171.933"
        transform="matrix(0.946914 0.321488 -0.332053 0.943261 57.0898 108.84)"
        fill="#ABE69E"
      />
      <rect
        width="384.276"
        height="115.971"
        transform="matrix(0.946914 0.321488 -0.332053 0.943261 208.953 0)"
        fill="#214F9A"
      />
    </svg>
  );
};
export const RoundShape = () => {
  return (
    <svg
      width="192"
      height="96"
      viewBox="0 0 192 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="roundShape"
    >
      <path
        d="M154.839 80.8136C154.839 97.7143 179.908 95.6384 192 96L-1.52588e-05 96L-5.99858e-06 4.62802e-08C-6.91112e-06 9.46028 11.1995 15.0048 19.5111 17.4012C22.3903 18.2314 25.3968 18.4407 28.3933 18.4407L122.839 18.4407C140.512 18.4407 154.839 32.7675 154.839 50.4407L154.839 80.8136Z"
        fill="white"
      />
    </svg>
  );
};
export const SortingIcon = () => {
  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <div className="blogFilter">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 7H20"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 12L14 12"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 17H6"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </DropdownTrigger>
      <DropdownMenu DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem key="login">Blog 1</DropdownItem>
        <DropdownItem key="register">Blog 2</DropdownItem>
        <DropdownItem key="register">Blog 3</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
export const FilterIcon = () => {
  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <div className="blogFilter">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8258 5H6.17422C5.31987 5 4.85896 6.00212 5.41496 6.65079L9.75926 11.7191C9.91461 11.9004 10 12.1312 10 12.3699V17.382C10 17.7607 10.214 18.107 10.5528 18.2764L12.5528 19.2764C13.2177 19.6088 14 19.1253 14 18.382V12.3699C14 12.1312 14.0854 11.9004 14.2407 11.7191L18.585 6.65079C19.141 6.00212 18.6801 5 17.8258 5Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </DropdownTrigger>
      <DropdownMenu DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem key="login">Blog 1</DropdownItem>
        <DropdownItem key="register">Blog 2</DropdownItem>
        <DropdownItem key="register">Blog 3</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
export const Arrow = ({ classNames }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${classNames ? classNames : ""} arrowImag z-20`}
    >
      <path
        d="M8.72266 25.1454L25.1449 8.72314M25.1449 8.72314H14.1967M25.1449 8.72314V19.6713"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const AccreOverImg = ({ className, styled, fill, accreImg }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="154"
      height="88"
      viewBox="0 0 154 88"
      fill="none"
      className={`accreOverImg ${className} ${
        accreImg ? "!-right-[1.8px] !-bottom-[1.5px] !stroke-[0.5px]" : ""
      }`}
      style={styled}
    >
      <path
        d="M31.1935 75.4634C31.1935 91.1318 10.8249 89.2073 1 89.5425L157 89.5425L157 0.542483C157 8.73704 149.056 13.7625 142.505 16.1993C139.31 17.3876 135.872 17.6385 132.463 17.6385L63.1935 17.6385C45.5204 17.6385 31.1935 31.9654 31.1935 49.6385L31.1935 75.4634Z"
        fill={fill ? fill : "white"}
        stroke="black"
      />
    </svg>
  );
};
export const AccreOverImgBig = ({ className, styled, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="188"
      height="87"
      viewBox="0 0 188 87"
      fill="none"
      className={`accreOverImg ${className}`}
      style={styled}
    >
      <path
        d="M37.7742 74.9209C37.7742 90.5893 12.9662 88.6648 1 89L191 89L191 2.04744e-07C191 8.84538 179.727 13.9983 171.482 16.1887C168.766 16.9104 165.947 17.096 163.136 17.096L69.7742 17.096C52.1011 17.0961 37.7742 31.423 37.7742 49.0961L37.7742 74.9209Z"
        fill={fill ? fill : "white"}
        stroke="black"
      />
    </svg>
  );
};
export const BgYellowWrap = () => {
  return (
    <svg
      width="402"
      height="663"
      viewBox="0 0 402 663"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="bgYellowWrap z-0 w-[360px] h-[360px] sm:w-[402px] sm:h-[663px]"
    >
      <rect
        width="604.591"
        height="243.115"
        transform="matrix(0.896313 0.443422 -0.234831 0.972036 57.0898 158.595)"
        fill="#FFDA73"
      />
      <rect
        width="405.97"
        height="163.984"
        transform="matrix(0.896313 0.443422 -0.234831 0.972036 208.953 0)"
        fill="#214F9A"
      />
    </svg>
  );
};
export const SearchInput = styled(Input)`
  font-size: 16px;

  ::placeholder {
    background: transparent;
    color: #c0c0c0;
    font-weight: 400;
  }
`;
export const WrapperOuter = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
`;
export const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const AboutContent = styled.div``;
export const AboutRight = styled.div`
  position: relative;
  .roundShape {
    position: absolute;
    bottom: -1px;
    z-index: 10;
    left: 0;
    right: auto;
  }
  .learnMore {
    position: absolute;
    bottom: 0px;
    z-index: 11;
    left: 0px;
    right: auto;
    background: #316bcb;
    color: #fff;
    padding: 29px 25px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 600;
  }
`;
export const AcademyHeader = styled.div``;
export const Main = styled.main``;

export const ContentContainer = styled.div`
  max-width: ${(props) =>
    props.session ? "1180px" : props.maxWidth ? props.maxWidth : "1280px"};
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  gap: 4rem;
  margin: auto;
  width: 100%;
  padding: 0;
  position: relative;
  @media (min-width: 1599px) {
    max-width: ${(props) =>
      props.session ? "1180px" : props.maxWidth ? props.maxWidth : "1520px"};
  }
  @media (max-width: 1399px) {
    max-width: 1160px;
  }
`;
export const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.curriculum ? "column" : props.column ? "column" : "row"};
  flex-wrap: wrap;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  position: relative;
  gap: ${(props) => (props.gap ? props.gap : "")};
`;
export const PrimaryBtn = styled.button`
  background: #316bcb;
  color: #fff;
  padding: ${(props) => (props.sessionList ? "14px 19px" : "16px 25px")};
  border-radius: 100px;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 1299px) {
    padding: 14px 22px;
    font-size: 14px;
  }
`;
export const FillerBtn = styled.button`
  background: #0096ac;
  color: #fff;
  padding: 14px 20px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 600;
`;
export const SecondaryOuter = styled.button`
  border-radius: 100px;
  border: ${(props) => (props.borderWidth ? props.borderWidth : "1px solid")};
  border-color: ${(props) => (props.borderColor ? props.borderColor : "#000")};
  width: auto;
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.padding ? props.padding : "13px 20px")};
  font-weight: 600;
  font-size: ${(props) => (props.size ? props.size : "16px")};
  color: ${(props) => (props.color ? props.color : "#000")};
  transition: all 0.5s;

  &:hover {
    background: #000;
    color: #fff;
  }
`;

export const SecondaryLink = styled(Link)`
  border-radius: 100px;
  border: 1px solid #000;
  max-width: 200px;
  display: flex;
  justify-content: center;
  padding: 13px 20px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  transition: all 0.5s;

  &:hover {
    background: #000;
    color: #fff;
  }
`;
export const Layout = styled.div`
  width: 100%;
  position: relative;
`;
export const Wrapper = styled.div`
  position: relative;
`;
export const Columns = styled.div`
  position: relative;
  max-width: 100%;
  text-wrap: wrap;
`;
export const SubHead = styled.h4`
  position: relative;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.54px;
  color: ${(props) => (props.light ? "#fff" : "#000")};
`;
export const HeroSec = styled.div`
  width: 100%;
`;
export const Heading = styled.h1`
  color: ${(props) => (props.light ? "#fff" : "#000")};
  font-size: ${(props) => (props.size ? props.size : "60px")};
  font-weight: 800;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "60px")};
  @media (max-width: 1024px) {
    font-size: 32px;
    line-height: 32px;
  }
`;
export const SubHeading = styled.h3`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "800")};
  line-height: normal;
  color: ${(props) => (props.color ? props.color : "")};
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
export const H2Heading = styled.h2`
  font-weight: 800;
  line-height: normal;
  color: ${(props) => (props.color ? props.color : "")};
  font-size: 28px;
`;
export const H4Heading = styled.h4`
  font-weight: 800;
  line-height: normal;
  color: ${(props) => (props.color ? props.color : "")};
  font-size: 20px;
`;
export const SubHeadingNew = styled.h4`
  font-size: ${(props) => (props.size ? props.size : "")};
  font-weight: 800;
  line-height: normal;
  position: relative;
`;
export const SubTitle = styled.h4`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  color: ${(props) => (props.color ? props.color : "inherit")};
`;
export const TP = styled.p`
  font-size: ${(props) => (props.size ? props.size : "16px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  letter-spacing: 0.48px;
  color: ${(props) =>
    props.session ? "#000" : props.color ? props.color : ""};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "170%")};
  @media (min-width: 1024px) {
    font-size: ${(props) => (props.largSize ? props.largSize : "")};
  }
`;
export const Para = styled.p`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  letter-spacing: 0.48px;
  color: ${(props) => (props.textLight ? "#fff" : "#000")};
`;
export const BrowseContainer = styled.div`
  .subHeading {
    margin-bottom: 50px;
    text-align: center;
  }
`;
export const BrowseCate = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.v3 ? "1fr" : "repeat(3, 1fr)")};
  gap: 2.5rem;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;
export const BrowseCateContent = styled.h4`
  position: absolute;
  z-index: 22;
  color: #fff;
  display: block;
  font-weight: 800;
  max-width: 18rem;
`;
export const BrowseCard = styled.div`
  position: relative;
  max-width: 100%;
`;
export const HeroTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
`;
export const HeroSlider = styled.div``;
export const LearnMoreBtn = styled(Button)``;
export const CommonCard = styled.div`
  position: relative;
  padding: 0;
`;
export const CardHeader = styled.div`
  border-bottom: ${(props) => (props.session ? "1px solid #F9F5EC" : "")};
  padding: ${(props) => (props.session ? "32px 40px 15px" : "")};
`;
export const CardBody = styled.div`
  padding: ${(props) => (props.session ? "32px" : "")};
`;
export const CardFooter = styled.footer`
  background: ${(props) => (props.session ? "#000" : "")};
  border-radius: ${(props) => (props.session ? "0 0 24px 24px" : "")};
  border: ${(props) => (props.session ? "1px solid #000" : "")};
  padding: ${(props) =>
    props.session
      ? "40px 40px"
      : props.sessionList
      ? "12px 40px"
      : props?.padding
      ? props?.padding
      : ""};
  color: ${(props) => (props.color ? props.color : "#fff")};
`;
export const CardImg = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  img {
    width: 100%;
  }
`;
export const CategoryBtn = styled.button`
  position: absolute;
  top: 16px;
  background: #fff;
  left: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #1a1414;
  padding: 5px 10px;
  border-radius: 50px;
`;

export const CardContent = styled.div`
  padding: ${(props) => (props.session ? "0" : "1rem 0.6rem")};
`;
export const CardTitle = styled.p`
  font-weight: 700;
  color: #1a1414;
  font-size: ${(props) => (props.session ? "24px" : "18px")};
  margin-bottom: 8px;
`;
export const CardSubtitle = styled.p`   
    font-size: 14px;
    font-weight: 400;
    color: #000;
}`;
export const HighLight = styled.span`
  color: #570062;
  font-weight: 800;
`;
export const CardDesc = styled.p`
  color: #606060;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  margin: 2px 0;
`;
export const ScheduledDates = styled.div`
  display: flex;
  gap: ${(props) => (props.session ? "21px" : "14px")};
  margin-top: 3px;
  margin-bottom: 34px;
  flex-wrap: wrap;
  max-height: 200px;
  height: 100%;
  overflow: auto;
  button {
    flex: 0 0 30%;
  }
`;
export const ScheduledTime = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #000;
`;
export const DateContainer = styled.button`
  background: #fff;
  border: 1.8px solid #e6ddc7;
  padding: 13px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;

  &.active {
    border-color: #0096ae;
  }
`;
export const DateBtn = styled.button`
  background: #fff;
  border: 1.8px solid #e6ddc7;
  padding: 13px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;

  &.active {
    border-color: #0096ae;
  }
`;
export const Span = styled.span`
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background: #0096ae;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: 0px;
    top: 30px;
  }
`;
export const TextButton = styled.button`
  font-size: 12px;
  text-transform: uppercase;
  color: #0096ac;
  font-weight: 500;
  letter-spacing: 1.08px;
  line-height: 15.6px;
`;
export const AccreContainer = styled.div`
  position: relative;
  width: auto;
  border: 1.2px solid ${(props) => (props?.bgColor ? props?.bgColor : "#000")};
  border-radius: 16px;
  z-index: 1;
  background: ${(props) => (props?.bgColor ? props?.bgColor : "#fff")};
  color: ${(props) => (props?.color ? props?.color : "#000")};
`;
export const BrandContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  max-width: 900px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const ClientReviewContainer = styled.div``;
export const ReviewCard = styled.div`
  background: #fff;
  border: 1px solid #000;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 10px;
  height: 100%;
`;
export const ReviewDesc = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  max-width: 307px;
  margin: 20px 0 32px 0;
  display: -webkit-box;
  // -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;
export const UserContent = styled.div``;
export const UserName = styled.p`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 18px;
`;
export const UserDesination = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
`;
export const BlogDate = styled.h6`
  font-size: 12px;
  font-weight: 400;
  color: #1a1414;
  margin-bottom: 8px;
`;
export const SecondaryBtn = styled.button`
  background: ${(props) =>
    props.session ? "#ABE69E" : props.bookings ? props.bookings : "#000"};
  border-radius: 100px;
  padding: ${(props) => (props.padding ? props.padding : "13px 17px")};
  color: ${(props) =>
    props.session ? "#000" : props.color ? props.color : "#fff"};
  font-weight: 600;
  font-size: ${(props) => (props.size ? props.size : "13px")};
  z-index: 2;
`;

export const SearchBox = styled.div`
  position: absolute;
  bottom: -1.25rem;

  @media (min-width: 768px) {
    bottom: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  z-index: 10;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }

  max-width: 89%;

  @media (min-width: 768px) {
    max-width: 70%;
  }
`;
export const ContactDetails = styled.div`
  display: grid;
  gap: 1.25rem; /* gap-5 in Tailwind is 1.25rem */
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const ContactCards = styled.div`
  border: 1px solid;
  border-radius: 32px;
  padding: 36px 24px 24px 24px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const FilledImageCard = styled.div`
  border-radius: 32px;
  padding: 0px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const ContactArea = styled.h2`
  color: #000;
  font-weight: 800;
  font-size: 18px;
  letter-spacing: 0.54px;
`;
export const Contact = styled.h3`
  font-size: 14px;
  color: #000;
  font-weight: 400;
  line-height: 23.8px;
`;
export const ContactMail = styled.h3`
  font-size: 14px;
  color: #000;
  font-weight: 400;
  line-height: 23.8px;
`;
export const Address = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
`;
export const CourseListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 26% 1fr;
  }

  gap: 5rem; /* gap-20 in Tailwind is 5rem */
`;
export const FilterHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FiltersDetails = styled.div``;
export const FilterKeywords = styled.div``;
export const FilterTopics = styled.div``;
export const AllCoursesContainer = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 24rem; /* h-96 in Tailwind is 24rem */
  max-height: 100%;
  width: 100%;
  margin-top: 0;
  margin-bottom: 2.5rem; /* mb-10 in Tailwind is 2.5rem */

  @media (min-width: 1024px) {
    margin-bottom: 5rem; /* lg:mb-20 */
    margin-top: 0;
  }

  border-radius: 1.5rem; /* rounded-3xl */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  color: #fff;
  position: relative;
  padding: 2rem; /* p-8 in Tailwind is 2rem */

  @media (min-width: 1024px) {
    padding: 4rem; /* lg:p-16 */
  }
`;
export const Grid = styled.div`
  display: ${(props) => (props.upcoming ? "flex" : "grid")};
  grid-template-columns: ${(props) =>
    props.v2
      ? "20% 1fr"
      : (props) =>
          props.v3
            ? "repeat(3, 1fr)"
            : (props) =>
                props.curriculum
                  ? "21% 1fr"
                  : props.grid
                  ? props.grid
                  : "repeat(2, 1fr)"};
  gap: ${(props) =>
    props.v2
      ? "60px"
      : (props) =>
          props.v3
            ? "25px"
            : (props) =>
                props.session
                  ? "70px"
                  : (props) =>
                      props.sessionLists
                        ? "90px"
                        : (props) =>
                            (
                              props.curriculum
                                ? "80px"
                                : (props) => props.testimonials
                            )
                              ? "37px"
                              : props.gap
                              ? props.gap
                              : "10px"};

  align-items: ${(props) =>
    props.v2 ? "start" : props.itemPosition ? props.itemPosition : "center"};
  justify-content: ${(props) => (props.upcoming ? "space-between" : "")};
`;
export const ObjectiveCard = styled.div``;
export const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 50% 50%;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 68% 32%;
  }

  gap: 2rem; /* gap-8 is 2rem */
  margin-top: 5rem; /* mt-20 is 5rem */
  margin-bottom: 4rem; /* mb-16 is 4rem */
`;
export const ContactContent = ({ background, children }) => (
  <div
    className="
      bg-no-repeat
      bg-cover
      bg-center
      w-full
      flex
      justify-between
      flex-col
      h-[473px]
      max-h-full
      text-white
      rounded-2xl
      p-[60px_2rem_60px_40px]
      xl:p-[60px_13rem_60px_40px]
      2xl:p-[60px_13rem_60px_40px]

    "
    style={{
      backgroundImage: background ? `url("${background}")` : "",
    }}
  >
    {children}
  </div>
);
export const ContactForm = styled.div``;
export const SendBtn = styled.button`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#0096ac"};
  color: ${(props) => (props.color ? props.color : "#fff")};
  padding: ${(props) => (props.padding ? props.padding : "13px 38px")};
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
`;
export const SessionContainer = styled.div``;
export const SessionTypes = styled.div`
  position: absolute;
  display: flex;
  gap: 1rem; /* gap-4 is 1rem */
  bottom: -39px; /* Custom negative bottom positioning */

  @media (min-width: 768px) {
    bottom: 0; /* md:bottom-0 */
  }

  z-index: 10;
  align-items: center;
  max-width: 60%;
  white-space: nowrap;
  width: 100%;
  overflow: auto;
  padding-bottom: 0.75rem; /* pb-3 is 0.75rem */

  @media (min-width: 768px) {
    width: auto; /* md:w-auto */
  }
`;
export const Counter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const DropdownOuter = styled.div`
  border-radius: 100px;
  border: 1px solid #000;
  width: auto;
  display: flex;
  justify-content: center;
  padding: 11px 13px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
`;
export const Tab = styled(Link)`
  font-weight: 600;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom-width: 1px;
  border-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// Testimonials
export const UserCard = styled.div`
  position: relative;
  z-index: 11;
  border-radius: 16px;
  overflow: hidden;

  ::after {
    content: "";
    position: absolute;
    background-image: linear-gradient(
      180deg,
      rgba(94, 94, 94, 0) 41.65%,
      #000 89.37%
    );
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;
export const UserImage = styled.div``;
export const ProfileDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  padding: 30px;
  color: #fff;
  z-index: 111;
`;
export const ProfileDesc = styled.p`
  line-height: normal;
  margin: 16px 0 14px;
  color: #fff;
`;
export const BlogBanner = styled.div`
  background-size: cover;
  background-position: center;
  height: 487px;
  width: 100%;
  border-radius: 1rem; /* rounded-2xl is equivalent to 1rem */
  margin-top: 0;
  margin-bottom: 2.5rem; /* mb-10 is 2.5rem */
  position: relative;
`;
export const ReadingTime = styled.button`
  background: #fff;
  padding: 8px 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  left: 23px;
  top: 19px;
  font-size: 14px;
  color: #000;
`;
export const BlogsData = styled.div``;
export const BlogContent = styled.span``;
export const StyledList = styled.ul`
  list-style-type: disc;
  li {
    margin-bottom: 10px;
  }
  li::marker {
    color: #214f9a;
    font-size: 1.5em;
  }
`;
export const OurBlogContainer = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  max-height: 100%;
  width: 100%;
  margin-top: 1.25rem; /* mt-5 is 1.25rem */
  margin-bottom: 5rem; /* mb-20 is 5rem */
  border-radius: 1.5rem; /* rounded-3xl is equivalent to 1.5rem */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const ChartImg = styled.div``;
export const Enquire = styled.div`
  position: relative;
`;

// Form Bottom Shape
export const ContactWrapShape = ({ space, stroke }) => {
  return (
    <svg
      width="144"
      height="86"
      viewBox="0 0 144 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`contactWrapShape ${space ? space : ""}`}
    >
      <path
        d="M10.2056 75.7627C10.2056 91.6071 -12.195 89.661 -23 90L148.563 90L148.563 1.66558e-06C148.563 8.61026 139.131 13.7606 131.785 16.1095C128.793 17.066 125.635 17.2881 122.494 17.2881L42.2056 17.2881C24.5325 17.2881 10.2056 31.615 10.2056 49.2881L10.2056 75.7627Z"
        fill="white"
        stroke={stroke ? stroke : "black"}
      />
    </svg>
  );
};

export const CertificateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  background: #ffda73;
  border-radius: 16px;
  padding: 4rem 5rem;
  gap: 10px;
  @media (max-width: 640px) {
    padding: 6rem 3rem;
  }
`;
